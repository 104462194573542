import Button from './button';
import Counter from './counter';
import Input from './input';
import Modal from './modal';
import Overlay from './overlay';
import ProductImg from '@/assets/products/product.svg';
import {Confidenciality} from '@/components/confidenciality';
import IconAlert from '@/icons/alert';
import {extractFormValues} from '@/pages/associate/passenger';
import {CartItem, useGlobalStore} from '@/stores';
import {useAuth} from '@/stores/auth';
import {useSdk} from '@/stores/sdk';
import {useTunnel} from '@/stores/tunnel';
import {cleanProductTitle} from '@/utils/cleanProductTitle';
import {formatPrice} from '@/utils/formatPrice';
import {
  dispatchGAEvent,
  formatCartToGa,
  formatCategoryNameForGA,
  formatItemVariantForGA,
  formatPriceForGA,
} from '@/utils/googleAnalytics';
import {useNavigate, useSearchParams} from '@solidjs/router';
import {Icon} from 'solid-heroicons';
import {checkCircle, trash, x} from 'solid-heroicons/solid';
import {createEffect, createResource, createSignal, For, Match, onMount, ParentProps, Show, Switch,} from 'solid-js';
import {createStore} from 'solid-js/store';
import {Portal} from 'solid-js/web';

export default function Cart() {
  const [sdk] = useSdk();
  const [auth] = useAuth();
  const [params] = useSearchParams();
  const [_, tunnelActions] = useTunnel();
  const go = useNavigate();
  const [globalState, actions] = useGlobalStore();
  const [tunnel] = useTunnel();
  const [loading, setLoading] = createSignal(false);

  const [productToDelete, setProductToDelete] = createSignal<CartItem>();
  const [children] = createResource(sdk.customer.children);

  const isCykleo = () => globalState.cart.some((item) => item.item.product.variants.some((variant) => variant.providerId === "CYKLEO"));
  const hasCykleoOption = () => globalState.cart?.some((item) => item.item.options?.some((option) => option.variants.some((variant) => variant.providerId === "CYKLEO")))

  const containCykleo = () => isCykleo() || hasCykleoOption();

  const hasWarrantyDeposit = () => globalState.cart.some((item) => item.item.product.warrantyDepositAmount) || globalState.cart.some((item) => item.item.options?.some((option) => option.warrantyDepositAmount));

  onMount(() => {
	dispatchGAEvent('view_cart', null, auth.user, {
	  ecommerce: {
		value: formatPriceForGA(actions.totalCart()),
		currency: 'EUR',
		items: formatCartToGa(globalState.cart),
	  },
	});

	if (params['reset-tunnel']) {
	  tunnelActions.reset();

	  const url = new URL(window.location.href);
	  url.searchParams.delete('reset-tunnel');
	}
  });

  const hasAtLeastOneYearlySubscriptionProductInCart = () => {
	return globalState.cart.some(
		(item) => item.item.product.isYearlySubscription,
	);
  };

  function findPassenger(passengerId: string) {
	if (auth.user.thalesCustomerId === passengerId) {
	  return `${auth.user.firstName} ${auth.user.lastName}`;
	}

	if (children.loading) {
	  return '';
	}

	const child = children().find(
		(child) => child.thalesCustomerId === passengerId,
	);

	if (!child) {
	  return '';
	}

	return `${child.firstName} ${child.lastName}`;
  }

  function closeCart() {
	const url = new URL(window.location.href);
	url.searchParams.delete('cart-open');

	let path = url.pathname;

	if (url.search) {
	  path += url.search;
	}

	if (url.hash) {
	  path += url.hash;
	}

	return go(path);
  }

  // Voucher part
  const [voucher, setVoucher] = createStore({
	loading: false,
	error: '',
  });

  async function handleVoucher(code: string) {
	if (!code) return;

	try {
	  setVoucher({loading: true, error: ''});

	  const voucher = await sdk.applyVoucher(code, {
		products: globalState.cart.map(({item}) => ({
		  productId: item.product.id,
		  qty: item.quantity,
		  support: item.delivery,
		})),
	  });

	  actions.set('voucher', {...voucher, code});
	} catch (error) {
	  const message = error?.cause?._data?.message;

	  if (message === 'PROMOTIONAL_CODE_NOT_FOUND') {
		setVoucher(
			'error',
			"Ce code n'est pas valable. Vérifiez votre code et réessayer.",
		);
	  } else if (message === 'PROMO_PRODUCT_NOT_FOUND') {
		setVoucher(
			'error',
			"Ce code n'est pas valable. Vérifiez votre code et réessayer.",
		);
	  } else if (message === 'BASKET_ELIGIBLE_PRODUCT_MISSING') {
		setVoucher(
			'error',
			"Ce code n'est pas valable. Vérifiez votre code et réessayer.",
		);
	  } else {
		// TODO: Handle error
		console.error(error.cause);
		setVoucher(
			'error',
			"Ce code n'est pas valable. Vérifiez votre code et réessayer.",
		);
	  }
	} finally {
	  setVoucher('loading', false);
	}
  }

  async function applyVoucher(event: SubmitEvent) {
	event.preventDefault();

	const form = event.target as HTMLFormElement;
	const {code} = extractFormValues(form, ['code']);

	await handleVoucher(code);
  }

  onMount(() => {
	if (globalState.voucher) {
	  handleVoucher(globalState.voucher.code);
	}
  });

  createEffect(() => {
	// Empty voucher if cart is empty
	if (globalState.cart.length === 0) {
	  setVoucher({error: '', loading: false});
	  actions.set({voucher: null});
	}
  });

  async function onClickBeginCheckout() {

	try {
	  setLoading(true)
	  await sdk.customer.acceptCGU();
	} catch (error) {
	  console.error(error);
	} finally {
	  setLoading(false)
	}

	//Check Closure status
	const closureStatus = await sdk.closureStatus();
	actions.set('closureStatus', closureStatus);

	if (closureStatus.closed) {
	  return go('/');
	}

	dispatchGAEvent('begin_checkout', null, auth.user, {
	  ecommerce: {
		value: formatPriceForGA(actions.totalCart()),
		currency: 'EUR',
		items: formatCartToGa(globalState.cart),
	  },
	});

	return go('/cart/methods');
  }

  const shouldCheckAdditionalTerms = () => {
	for (const {item} of globalState.cart) {
	  if (item.product.title.toLowerCase().includes('tbm + train')) {
		return true;
	  }
	}
	return false;
  };

  const isSubmitDisabled = () => {
	if (!globalState.cart.length) return true;

	if (!globalState.termsAccepted) return true;

	if (containCykleo() && !globalState.cykleoTermsAccepted) return true;
  };

  return (
	  <>
		<Modal class="shadow-2xl"
			   open={containCykleo() && hasWarrantyDeposit() && !globalState.cykleoWarrantyDepositInfoRead}>
		  <div class="flex flex-col items-center space-y-4">
			<p>
			  En souscrivant à l’offre Le Vélo, vous vous engagez a réaliser un dépôt de garantie de 140€.
			  Pas d’inquiétude, le dépôt de garantie sera débité seulement si un vélo est fortement dégradé ou non
			  restitué.
			</p>

			<Button variant="primary" onClick={() => actions.set('cykleoWarrantyDepositInfoRead', true)}>
			  J'ai compris
			</Button>
		  </div>
		</Modal>
		<Portal mount={document.getElementById('modal')}>
		  <aside class="fixed right-0 top-0 z-40 flex h-screen w-full max-w-xl flex-col overflow-auto bg-white">
			<section class="relative flex flex-col bg-gray-100">
			  <div class="sticky top-0 bg-white px-6 py-3">
				<h2 class="text-lg font-semibold">Mon panier</h2>

				<button
					type="button"
					onClick={closeCart}
					class="absolute right-6 top-3"
				>
				  <span class="sr-only">Fermer le panier</span>
				  <Icon path={x} class="h-6 "/>
				</button>
			  </div>

			  <div class="flex-1 space-y-4 overflow-auto p-6">
				<For
					each={globalState.cart}
					fallback={<p>Votre panier est vide</p>}
				>
				  {(cartItem) => {
					const passenger = () => findPassenger(cartItem.passenger);
					const voucherProduct = () =>
						globalState.voucher?.products?.find(
							(product) =>
								product.productId === cartItem.item.product.id,
						);

					const quantity = () => {
					  if (voucherProduct()) {
						if (voucherProduct().qty !== cartItem.item.quantity) {
						  return voucherProduct().qty;
						}
					  }

					  return (
						  cartItem.item?.temporalValidity?.length ??
						  cartItem.item.quantity
					  );
					};

					const price = () => {
					  // If we have no method defined yet we want to show the monthly price if it has one
					  // and fallback on the full retail price otherwise
					  if (!globalState.method) {
						return cartItem.item.product?.monthlyPrice ?? cartItem.item.product.price;
					  }

					  if (
						  globalState.method === 'SCHEDULED' &&
						  cartItem.item.product.isYearlySubscription
					  ) {
						return cartItem.item.product.monthlyPrice;
					  }

					  return cartItem.item.product.price;
					};

					return (
						<>
						  <article class="flex flex-col rounded-lg bg-white p-4">
							<main class="flex space-x-4">
							  <img
								  src={ProductImg}
								  class="h-14 w-14 rounded-full"
							  />

							  <div class="flex-1">
								<div class="grid grid-cols-[1fr,auto] gap-x-2">
								  <h3 class="text-sm font-semibold">
									{cleanProductTitle(cartItem.item.product.title)}
								  </h3>

								  <Show
									  when={
										cartItem.item.product.isYearlySubscription
									  }
									  fallback={
										<>
										  <p class="text-lg">
											{/* TODO: Handle localisation */}
											{formatPrice(cartItem.item.product.price)}
										  </p>

										  <Show when={quantity() > 1}>
											<p class="col-span-2 ml-auto text-sm text-gray-500">
											  Sous-total :{' '}
											  {formatPrice(
												  quantity() *
												  cartItem.item.product.price,
											  )}
											</p>
										  </Show>
										</>
									  }
								  >
									<Show when={cartItem.item.product.isMonthlySubscription}
										  fallback={
											<>
											  <p class="font-semibold">
												{cartItem.item.product.price ? (formatPrice(cartItem.item.product.price) + ' /an') : 'Gratuit'}
											  </p>
											  <Show when={cartItem.item.product.monthlyPrice}>
												<p class="col-start-2 text-sm text-gray-400">
												  ou{' '}
												  {formatPrice(
													  cartItem.item.product.monthlyPrice)}
												  /mois
												</p>
											  </Show>
											</>
										  }>
									  <div>
										<p class="font-semibold">
										  {formatPrice(
											  cartItem.item.product.monthlyPrice,
										  )}
										  /mois
										</p>

										<p class="text-sm text-gray-400">
										  ou{' '}
										  {formatPrice(cartItem.item.product.price)}
										  /an
										</p>
									  </div>
									</Show>
								  </Show>
								</div>

								<hr class="my-4"/>

								<dl class="grid grid-cols-2 text-sm">
								  <Show when={cartItem.item.delivery && !isCykleo}>
									<dd class="col-start-1 font-semibold">
									  Support
									</dd>
									<dt class="col-start-2">
									  <Switch>
										<Match
											when={cartItem.item.delivery === 'RELOAD'}
										>
										  Carte TBM
										</Match>
										<Match
											when={cartItem.item.delivery === 'TICKET'}
										>
										  Ticket
										</Match>
									  </Switch>
									</dt>
								  </Show>

								  <Show when={passenger()}>
									<dd class="col-start-1 font-semibold">Pour</dd>
									<dt class="col-start-2">{passenger()}</dt>
								  </Show>
								</dl>
							  </div>
							</main>

							<footer class="mt-8 flex justify-between">
							  <button
								  type="button"
								  class="flex items-center space-x-2 text-sm font-semibold text-primary underline"
								  onClick={[setProductToDelete, cartItem]}
							  >
								<Icon path={trash} class="h-4"/>
								<span>Supprimer</span>
							  </button>

							  <Show
								  when={!cartItem.item.product.isYearlySubscription}
							  >
								<Show
									when={cartItem.item.product.isMonthlySubscription}
								>
								  <span class="ml-auto">Quantité : </span>
								</Show>
								<Counter
									class="ml-4"
									value={quantity()}
									max={
									  cartItem.item.delivery === 'RELOAD'
										  ? cartItem.item.product.maxCardQuantity
										  : cartItem.item.product.maxTicketQuantity
									}
									readOnly={
										cartItem.item.product.isMonthlySubscription ||
										Boolean(voucherProduct())
									}
									onValueChange={(value) => {
									  actions.updateCartQuantity(cartItem.id, value);
									}}
									min={1}
								/>
							  </Show>
							</footer>
						  </article>

						  <For each={cartItem.item.options}>
							{(option) => (
								<article
									class="mt-4 flex items-center justify-between rounded-lg bg-white p-4 font-semibold">
								  <h2 class="text-sm">{option.title}</h2>

								  <span>{formatPrice(option.price)}/an</span>
								</article>
							)}
						  </For>
						</>
					)

				  }}
				</For>
			  </div>
			</section>

			<section
				class="mt-8 px-6"
				classList={{hidden: !globalState.cart.length}}
			>
			  <form
				  onSubmit={applyVoucher}
				  class="flex w-full items-end space-x-4"
			  >
				<Input
					name="code"
					id="code"
					label="Code promotionnel"
					value={globalState.voucher?.code ?? ''}
					class="flex-1"
				/>
				<Button type="submit" loading={voucher.loading}>
				  Appliquer
				</Button>
			  </form>

			  <Show when={globalState.voucher}>
				<div role="alert" class="mt-4 flex gap-2 bg-green-600/50 p-2">
				  <Icon path={checkCircle} class="h-6 text-green-600"/>
				  <p class="flex-1 text-sm">
					Le code promo a bien été appliqué à votre panier.
				  </p>
				</div>
			  </Show>

			  <Show when={voucher.error}>
				<div role="alert" class="mt-4 flex gap-2 bg-secondary/50 p-2">
				  <IconAlert class="h-6 text-sm  text-secondary"/>
				  <p class="flex-1">{voucher.error}</p>
				</div>
			  </Show>
			</section>

			<section
				class={`mt-8 flex-col items-start space-y-2 px-6 text-sm ${
					globalState.cart.length ? 'flex' : 'hidden'
				}`}
			>
			  <label
				  for="terms"
				  class="mr-auto flex cursor-pointer items-center space-x-2"
			  >
				<input
					id="terms"
					name="terms"
					type="checkbox"
					checked={globalState.termsAccepted}
					onChange={(e) =>
						actions.set('termsAccepted', e.currentTarget.checked)
					}
				/>

				<span>
                J'accepte{' '}
				  <a
					  href="https://www.infotbm.com/fr/conditions-generales-de-vente-et-mentions-legales.html"
					  target="_blank"
					  rel="noopener"
					  class="underline"
				  >
                  les conditions générales de ventes
                </a>
              </span>
			  </label>

			  <Show when={containCykleo()}>
				<label
					for="cykleo-terms"
					class="mr-auto flex items-baseline cursor-pointer space-x-2"
				>
				  <input
					  id="cykleo-terms"
					  name="cykleo-terms"
					  type="checkbox"
					  checked={globalState.cykleoTermsAccepted}
					  onChange={(e) =>
						  actions.set('cykleoTermsAccepted', e.currentTarget.checked)
					  }
				  />

				  <span>
					J’autorise TBM à débuter la prestation avant la fin du délai de rétractation et je reconnais que je n’aurai pas de droit de rétractation si la prestation est totalement exécutée conformément à l’article L.221-28 du code de la consommation.
					Si mon abonnement débute dans un délai supérieur à 14 jours, je conserve mon droit de rétractation.
                </span>
				</label>
			  </Show>


			  <Show when={shouldCheckAdditionalTerms()}>
				<label
					for="additional-terms"
					class="mr-auto flex cursor-pointer items-center space-x-2"
				>
				  <input
					  id="additional-terms"
					  name="additional-terms"
					  type="checkbox"
				  />

				  <span>
                  J’accepte que mes données soient partagées à la SNCF dans le
                  cadre de la souscription au contrat TBM + Trains
                </span>
				</label>
			  </Show>
			</section>

			<Show when={globalState.cart.length}>
			  <Show when={globalState.voucher} keyed>
				{(voucher) => (
					<>
					  <div class="mt-4 flex justify-between px-6 text-sm font-semibold">
						<span>Prix initial TTC</span>
						<span>{formatPrice(voucher.oldBasketTotalValue)}</span>
					  </div>

					  <div class="mt-4 flex justify-between px-6 text-sm font-semibold">
						<span>Promo appliquée</span>

						<ul class="grid">
						  <For
							  each={voucher.products
								  .filter((product) => product.discounted)
								  .map((product) => ({
									...product,
									product: actions.findProduct(product.productId),
								  }))}
						  >
							{(product) => (
								<li>
								  <Switch
									  fallback={
										<span>
                                  -
										  {formatPrice(
											  product.oldUnitPrice - product.newUnitPrice,
										  )}{' '}
										  sur le {product.product?.title}
                                </span>
									  }
								  >
									<Match when={product.unitOff}>
                                <span>
                                  {product.product?.title} offert :{' '}
								  {product.unitOff}
                                </span>
									</Match>
									<Match when={product.percentOff}>
                                <span>
                                  -{product.percentOff}% sur le{' '}
								  {product.product?.title}
                                </span>
									</Match>
								  </Switch>
								</li>
							)}
						  </For>
						</ul>
					  </div>

					  <div class="mt-4 flex justify-between px-6 text-lg font-semibold">
						<span>Total TTC</span>
						<span>{formatPrice(voucher.newBasketTotalValue)}</span>
					  </div>
					</>
				)}
			  </Show>
			  <Show
				  when={
					  !hasAtLeastOneYearlySubscriptionProductInCart() &&
					  !globalState.voucher
				  }
			  >
				<div class="mt-4 flex justify-between px-6 text-lg font-semibold">
				  <span>Total TTC</span>
				  <span>{formatPrice(actions.totalCart())}</span>
				</div>
			  </Show>
			  <Button
				  loading={loading()}
				  variant="secondary"
				  class="sticky bottom-10 mx-auto mt-4"
				  disabled={isSubmitDisabled()}
				  onClick={onClickBeginCheckout}
			  >
				Valider mon panier
			  </Button>
			  <Button
				  type="button"
				  onClick={closeCart}
				  variant="base"
				  class="mx-auto my-4"
			  >
				Continuer mes achats
			  </Button>

			  <div class="mx-4 mb-8">
				<Confidenciality/>
			  </div>
			</Show>
		  </aside>
		</Portal>

		<Overlay open onClick={closeCart}/>

		<DeleteProductModal
			item={productToDelete()}
			open={!!productToDelete()}
			close={() => setProductToDelete()}
			onDeleted={() => {
			  setProductToDelete();
			  handleVoucher(globalState.voucher?.code);
			}}
		/>
	  </>
  );
}

interface Props {
  item?: CartItem;
  open: boolean;
  close: () => void;
  onDeleted: () => void;
}

function DeleteProductModal(props: ParentProps<Props>) {
  const [_, actions] = useGlobalStore();
  const [auth] = useAuth();

  async function onClickDeleteItem() {
	actions.removeFromCart(props.item.id);
	actions.set('termsAccepted', false);
	actions.set('cykleoTermsAccepted', false);
	actions.set('cykleoWarrantyDepositInfoRead', false);

	const item = props.item.item;

	const sumOptionPrice = item.options?.reduce(
		(sum, option) => sum + option.price,
		0,
	);

	const totalPrice = item.product.price * item.quantity + sumOptionPrice;

	const itemsGA = [
	  {
		item_id: item.product.id,
		item_name: item.product.title,
		item_variant: formatItemVariantForGA(item),
		item_brand: formatCategoryNameForGA(item.product.categoryId),
		price: formatPriceForGA(item.product.price),
		currency: 'EUR',
		quantity: item.quantity,
		item_category: formatCategoryNameForGA(item.product.categoryId),
	  },
	];

	dispatchGAEvent('remove_from_cart', null, auth.user, {
	  ecommerce: {
		value: formatPriceForGA(totalPrice),
		currency: 'EUR',
		items: itemsGA,
	  },
	});
	props.onDeleted();
  }

  return (
	  <Modal open={props.open} class="bottom-0" onClose={props.close} zIndex={60}>
		<main>
		  <p>
			Vous êtes sur le point de supprimer votre titre{' '}
			<span class="font-semibold">{props.item.item.product.title}</span>.
		  </p>
		  <p>Si vous validez la suppression, votre configuration sera perdue.</p>

		  <section class="mt-8 space-y-4 bg-white p-6 lg:flex lg:space-x-4 lg:space-y-0">
			<Button
				variant="secondary"
				class="w-full"
				onClick={onClickDeleteItem}
			>
			  Supprimer
			</Button>

			<Button
				type="button"
				variant="base"
				class="w-full"
				onClick={props.close}
			>
			  Annuler
			</Button>
		  </section>
		</main>
	  </Modal>
  );
}
